import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-durham-north-carolina.png'
import image0 from "../../images/cities/scale-model-of-pearl-mill-preserve-in-durham-north-carolina.png"
import image1 from "../../images/cities/scale-model-of-the-rocks-in-durham-north-carolina.png"
import image2 from "../../images/cities/scale-model-of-\"major\"-–-durham-bull-bronze-sculpture-in-durham-north-carolina.png"
import image3 from "../../images/cities/scale-model-of-piedmont-wildlife-center-in-durham-north-carolina.png"
import image4 from "../../images/cities/scale-model-of-duke-homestead-in-durham-north-carolina.png"
import image5 from "../../images/cities/scale-model-of-durham-distillery-in-durham-north-carolina.png"
import image6 from "../../images/cities/scale-model-of-sarah-p.-duke-gardens-in-durham-north-carolina.png"
import image7 from "../../images/cities/scale-model-of-american-tobacco-campus-in-durham-north-carolina.png"
import image8 from "../../images/cities/scale-model-of-bennett-place-in-durham-north-carolina.png"
import image9 from "../../images/cities/scale-model-of-audubon-north-carolina-in-durham-north-carolina.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Durham'
            state='North Carolina'
            image={image}
            lat='35.9811'
            lon='-78.9029'
            attractions={ [{"name": "Pearl Mill Preserve", "vicinity": "S Ellerbee Creek Trail, Durham", "types": ["park", "point_of_interest", "establishment"], "lat": 36.01092939999999, "lng": -78.90245470000002}, {"name": "The Rocks", "vicinity": "2520 Broad St, Durham", "types": ["park", "point_of_interest", "establishment"], "lat": 36.0337636, "lng": -78.90873210000001}, {"name": "\"Major\" \u2013 Durham bull bronze sculpture", "vicinity": "Durham", "types": ["point_of_interest", "establishment"], "lat": 35.9964808, "lng": -78.90170180000001}, {"name": "Piedmont Wildlife Center", "vicinity": "364 Leigh Farm Rd, Durham", "types": ["park", "point_of_interest", "establishment"], "lat": 35.9227547, "lng": -78.98266990000002}, {"name": "Duke Homestead", "vicinity": "2828 Duke Homestead Rd, Durham", "types": ["point_of_interest", "establishment"], "lat": 36.0357689, "lng": -78.92098870000001}, {"name": "Durham Distillery", "vicinity": "711 Washington St, Durham", "types": ["point_of_interest", "establishment"], "lat": 36.004419, "lng": -78.90292649999998}, {"name": "Sarah P. Duke Gardens", "vicinity": "420 Anderson St, Durham", "types": ["park", "point_of_interest", "establishment"], "lat": 36.0020104, "lng": -78.93113240000002}, {"name": "American Tobacco Campus", "vicinity": "300 Blackwell St #104, Durham", "types": ["point_of_interest", "establishment"], "lat": 35.9946036, "lng": -78.90340700000002}, {"name": "Bennett Place", "vicinity": "4409 Bennett Memorial Rd, Durham", "types": ["museum", "point_of_interest", "establishment"], "lat": 36.029262, "lng": -78.97508499999998}, {"name": "Audubon North Carolina", "vicinity": "807 E Main St Suite 2-220, Durham", "types": ["point_of_interest", "establishment"], "lat": 35.99085, "lng": -78.89007509999999}] }
            attractionImages={ {"Pearl Mill Preserve":image0,"The Rocks":image1,"\"Major\" – Durham bull bronze sculpture":image2,"Piedmont Wildlife Center":image3,"Duke Homestead":image4,"Durham Distillery":image5,"Sarah P. Duke Gardens":image6,"American Tobacco Campus":image7,"Bennett Place":image8,"Audubon North Carolina":image9,} }
       />);
  }
}